@font-face {
    font-family: "Montserrat";
    src: local("MontserratLight"),
      url("./assets/fonts/MontserratLight.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Montserrat";
    src: local("MontserratRegular"),
      url("./assets/fonts/MontserratRegular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Montserrat";
    src: local("MontserratMedium"),
      url("./assets/fonts/MontserratMedium.ttf") format("truetype");
    font-weight: 500;
}
  
@font-face {
    font-family: "Montserrat";
    src: local("MontserratSemiBold"),
        url("./assets/fonts/MontserratSemiBold.ttf") format("truetype");
    font-weight: 600;
}
  
@font-face {
    font-family: "Montserrat";
    src: local("MontserratBold"),
        url("./assets/fonts/MontserratBold.ttf") format("truetype");
    font-weight: 700;
}
  
@font-face {
    font-family: "Montserrat";
    src: local("MontserratExtraBold"),
        url("./assets/fonts/MontserratExtraBold.ttf") format("truetype");
    font-weight: 800;
}
